import * as React from 'react'
import clsx from 'clsx'
import { Container } from '@mui/material'
import pageStyles from '@assets/css/page.module.css'
import { CommonComponentProps } from '@controls/types'
import styles from './PageContainer.module.css'

export type PageContainerProps = CommonComponentProps

export const PageContainer: React.FC<PageContainerProps> = ({ children, className }) => {
  return (
    <div className={clsx(className, styles.containerWrap)}>
      <Container className={pageStyles.container}>{children}</Container>
    </div>
  )
}
