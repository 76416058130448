import * as React from 'react'
import { TitledContainerProps } from '@controls/types'
import { BreadCrumbsProps } from '@controls/BreadCrumbs'
import { PageContainer, PageContainerProps } from '..'
import styles from './PageContainer.module.css'
import backgroundImage from '@assets/images/backgroundAuto.png'
import BackgroundLogo from '@assets/icons/backgroundLogo.svg'
import { PageHead } from '@layout/Page/PageHead'

export type PageContainerWithHeadProps = Readonly<{
  title?: TitledContainerProps['title']
  breadCrumbs?: BreadCrumbsProps['breadCrumbs']
  showCar?: boolean
  showLogo?: boolean
  showBreadCrumbs?: boolean
}> &
  PageContainerProps

export const PageContainerWithHead: React.FC<PageContainerWithHeadProps> = ({
  title,
  breadCrumbs,
  showCar,
  showBreadCrumbs = true,
  showLogo,
  children,
  ...props
}) => {
  return (
    <PageContainer {...props}>
      {showCar && <img src={backgroundImage} alt="background" className={styles.backgroundImage} />}
      {showLogo && <img src={BackgroundLogo} alt="logo" className={styles.backgroundLogo} />}
      {showBreadCrumbs && <PageHead title={title} breadCrumbs={breadCrumbs} />}
      {children}
    </PageContainer>
  )
}
